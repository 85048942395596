/*eslint-disable*/
import React from "react";
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Accordion from "components/Accordion/Accordion.js";
import Button from "components/CustomButtons/Button.js";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
const useStyles = makeStyles(productStyle);

function GithubLink(props){
  const classes=props.classes
  if (props.github) {
    return (
    <Button
      href={props.github}
      target="_blank"
      color="github"
      simple
      className={classes.rightLinks}
    >
      <i className="fab fa-github" />
      View code
    </Button>
    )
  } 
  return (<p></p>)
}

function DemoLink(props){
  const classes=props.classes
  if (props.demo) {
    return (
      <Button
        href={props.demo}
        target="_blank"
        color="linkedin"
        simple
        className={classes.rightLinks}
      >
        <i className="material-icons">play_circle</i>
        View demo
      </Button>
    )
  } 
  return (<p></p>)
}

export default function ProgrammingPage() {
  const { id } = useParams();
  const classes = useStyles();
  const projects = useSelector((state) => state.projects);
  const project = projects['programming'][id];
  console.log(project);
  const images = project.images.map((image) => 
    {
      const container = {}
      container['original'] = image
      container['thumbnail'] = image
      return container
    }
  );
  console.log(images);

  return (
    <div className={classes.productPage}>
      <Header
        brand="epeder"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info"
        }}
      />
      <Parallax
        image={require("assets/img/bg-programming.jpeg")}
        filter="info"
        className={classes.pageHeader}
      >
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
          <GridContainer>
          </GridContainer>
            <GridContainer>
              <GridItem md={20} sm={6}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={0}
                  items={images}
                  showThumbnails={true}
                  renderLeftNav={(onClick,disabled) => {
                    return (
                      <button
                        className='image-gallery-left-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                  renderRightNav={(onClick,disabled) => {
                    return (
                      <button
                        className='image-gallery-right-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem md={6} sm={6}>
                <h2 className={classes.title}>{project.name}</h2>
                <h3 className={classes.mainPrice}>{project.tech}</h3>
                <Accordion
                  active={0}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Description",
                      content: (
                        <p>{project.description}</p>
                      )
                    },
                    {
                      title: "Tech Stack",
                      content: (
                        <p>{project.techstack}</p>
                      )
                    }
                  ]}
                />
              </GridItem>
              <GridItem >
                <GithubLink github={project.github_url} classes={classes}/>
                <DemoLink demo={project.demo} classes={classes}/>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="/"
                target="_blank"
                className={classes.footerBrand}
              >
                epeder.com
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/about-me"
                    target="_blank"
                    className={classes.block}
                  >
                    About me
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://www.linkedin.com/in/erictpedersen/"
                    target="_blank"
                    color="linkedin"
                    justIcon
                    simple
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
