let initialState = {
  projects: {
    "3d": [],
    "programming": [
      {
        "description": "This is the description of the project",
        "images": [
          "https://storage.googleapis.com/epeder-portfolio/static/img/would-you-rather-1.png",
          "https://storage.googleapis.com/epeder-portfolio/static/img/would-you-rather-2.png",
          "https://storage.googleapis.com/epeder-portfolio/static/img/would-you-rather-3.png"
        ],
        "name": "Would you rather?",
        "project": "Programming",
        "tech stack": "Describe the tech stack for the project",
        "url": "would-you-rather",
        "demo": "/app/memory-game"
      }
    ]
  }
}

export default function reducer(state = initialState, action){
  console.log(action.type);
  switch (action.type) {
    case "RECEIVE_PROJECTS":
      state.projects = action.payload
      return state
    default:
      return state
  }

}