/*eslint-disable*/
import React from "react";
import { useDispatch } from 'react-redux'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import { getProjects } from '../../utils/api'

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  getProjects(dispatch);

  return (
    <div>
      <Header
        brand="epeder.com"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "info"
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>
                  Portfolio for Eric Pedersen
                  <span className={classes.proBadge}>epeder</span>
                </h1>
                <h3 className={classes.title}>
                  A collection of programming, photography, and 3D artwork
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
      </div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="/"
                target="_blank"
                className={classes.footerBrand}
              >
                epeder.com
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/about-me"
                    target="_blank"
                    className={classes.block}
                  >
                    About me
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://www.linkedin.com/in/erictpedersen/"
                    target="_blank"
                    color="linkedin"
                    justIcon
                    simple
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}