import { useDispatch } from 'react-redux'

export function getProjects(dispatch) {
    return getProjectsApi().then((data) => {
        dispatch({type: "RECEIVE_PROJECTS", payload: data});
    });
}

export async function getProjectsApi() {
    const response = await fetch('/projects');
    const jsonData = response.json();
    return jsonData
}