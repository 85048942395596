/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import { Provider } from 'react-redux'
import reducer from './reducers'
import { createStore } from 'redux'

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ProgrammingPage from "views/ProgrammingPage/ProgrammingPage.js";
import SectionsPage from "views/SectionsPage/SectionsPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={createStore(reducer)} >
    <Router history={hist}>
      <Switch>
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/programming/:id" component={ProgrammingPage} />
        <Route path="/sections" component={SectionsPage} />
        <Route path="/error-page" component={ErrorPage} />
        <Route path="/" component={PresentationPage} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
